<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title>
          Клиенты
          <v-btn @click="openNewClientModal" class="ml-4 align-self-end">+ Добавить</v-btn>
          <!--          v-if="$store.state.user.city.id==1"-->
          <NewClient ref="newClientModal"/>
        </v-card-title>
        <v-card-text>
          <ClientsFilter :filters="filters" @filtersUpdated="filtersUpdated"/>
          <v-row>
            <v-col>
              <v-btn :loading="loading_table" @click="getClients()" class="mt-4 mr-2">Поиск</v-btn>
              <v-btn @click="filters = {...defaultFilters};getClients();" class="v-btn-danger mt-4">Очистить фильтры
              </v-btn>
              <v-btn
                  v-if="$store.state.user.rights.includes('downloadClients') || $store.state.user.login=='maria2' && showRevenueCost"
                  class="ml-2 mt-4 v-btn-transp" :href="downloadURL">
                Выгрузить
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn v-if="$store.state.user.rights.includes('sendPushToClients')"
                     class="ml-2 mt-4 v-btn-transp" @click="$refs.sendPush.open(prepareParams(), count)">
                Отправить пуши
                <v-icon>mdi-bell-outline</v-icon>
              </v-btn>
              <v-btn :class="{'v-btn-transp':!showChart}"
                     class="ml-2 mt-4" @click="showChart=!showChart;getClients();">
                График
                <v-icon>mdi-chart</v-icon>
              </v-btn>
              <v-btn v-if="$store.state.user.city.id==1" :class="{'v-btn-transp':!showRevenueCost}"
                     class="ml-2 mt-4" @click="showRevenueCost=!showRevenueCost;getClients();">
                Расходы/Доходы
                <v-icon>mdi-chart</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-checkbox v-if="showRevenueCost" @change="getClients()" label="По всем заказам клиентов" :true-value="1"
                      :false-value="0" v-model="revenueTableByOrders"/>
          <RevenueCost :table="revenueTable" v-if="showRevenueCost"></RevenueCost>
          <template v-if="showChart">
            <LineChartGenerator
                v-if="filters.order.dateclean[0] && filters.order.dateclean[1]"
                :chart-options="chartOptions"
                :chart-data="barChartData"
                chart-id="bar-chart"
                ref="chart"
                :width="400"
                :height="300"
            />
            <v-row v-else>
              <v-alert
                  style="width: 100%;"
                  dense
                  border="left"
                  type="error"
              >
                Чтобы отобразился график, выберите период в поле "Дата уборки" и нажмите "Поиск"
              </v-alert>
            </v-row>
          </template>
          <v-data-table
              v-else
              :headers="headers"
              :items="clients"
              item-key="n_id"
              @update:page="setPage($event)"
              :loading="loading_table"
              no-data-text="Клиентов нет"
              :footer-props="{'items-per-page-options':[20],showFirstLastPage: true,showCurrentPage: true}"
              loading-text="Загрузка..."
              :server-items-length="count"
              :page="page"
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr v-if="item.hasOwnProperty('name')">
                <td>{{ (page - 1) * 20 + index + 1 }}</td>
                <td><a @click="$eventBus.$emit('openClient', item.n_id)">{{ item.name }}</a></td>
                <td style="white-space: nowrap" v-on:click.stop><a :href="'tel: '+item.phone">{{ item.phone }}</a></td>
                <td>
                  <span v-if="item.stats.likes > 0" style="color: green">{{ item.stats.likes }}</span>
                  /
                  <span style="color: red;margin-right: 5px;">{{
                      item.stats.dislikes
                    }}</span>
                </td>
                <td>
                  <a v-if="item.order" :href="`#/orders/${item.order.n_id}`" target="_blank" role="link">
                    {{ $root.dateToRus(item.order.datetimecleanObj.date, 0, 1) }}
                    {{ item.order.datetimecleanObj.time }}
                  </a>
                </td>
                <td>
                  <a v-if="item.order" :href="`#/orders/${item.order.n_id}`" target="_blank" role="link">
                    {{ item.order.address.street }} {{ item.order.address.house }}
                  </a>
                </td>
                <td v-if="item.order">{{ item.order.promo }}</td>
                <td v-if="item.order" style="white-space: nowrap">{{ $root.printCost(item.order.total) }}</td>
                <td v-if="item.order">
                  <a class="cleaner" v-for="cleaner in item.order.cleaners" :key="cleaner.n_id"
                     v-on:click.stop="$eventBus.$emit('openCleaner', cleaner.n_id)" style="cursor: pointer;">
                    {{ $root.cleanerInitials(cleaner.name) }}
                  </a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <SendPush ref="sendPush"/>
  </v-app>
</template>

<script>

import SendPush from "./SendPush";
import {Line as LineChartGenerator} from "vue-chartjs/legacy"
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import NewClient from "../../containers/Modals/NewClient";
import RevenueCost from "../../components/RevenueCost";
import Date from "../../components/Date";
import DatePeriod from "../../components/DatePeriod";
import ClientsFilter from "@/components/ClientsFilter";

ChartJS.register(ChartJSPluginDatalabels, Title, Tooltip, Legend, ArcElement, CategoryScale, LineElement, LinearScale, PointElement)

export default {
  name: "Clients",
  components: {ClientsFilter, DatePeriod, Date, RevenueCost, NewClient, SendPush, LineChartGenerator},
  data() {
    let defaultFilters = {
      client_f: {defaultCard: '', ordersCount: '', gender: [], ordersCountSign: '>='},
      address: {},
      order: {dateclean: [], datecreate: [], first_order: [], withPromo: 0, withDiscount: 0, services: []},
      // layout: {showChart: false,},
    };
    let filters = {...defaultFilters}
    // filters.order.dateclean  = [this.$moment().startOf('isoWeek').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
    return {
      loading: false,
      revenueTable: {},
      showChart: false,
      showRevenueCost: false,
      revenueTableByOrders: 0,
      loading_table: true,
      count: 0,
      page: 1,
      filters,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: c => false,
            font: {
              weight: 'bold'
            },
            formatter: Math.round
          }
        },
      },
      defaultFilters: {...defaultFilters},
      headers: [
        {value: '', text: '№'},
        {value: 'name', text: 'Имя'},
        {value: 'phone', text: 'Телефон'},
        {value: 'stats', text: 'Оценки'},
        {value: 'order.datetimeclean', text: 'Дата уборки'},
        {value: 'address.street', text: 'Адрес'},
        {value: 'order.promo', text: 'Промокод'},
        {value: 'order.total', text: 'Цена'},
        {value: '', text: 'Клинеры'},
        // {value: 'order', text: 'Заказ'},
        // {value: '', text: 'Оценки'},
        // {value: '', text: 'Оценка уборки'},
        // {value: '', text: 'Клиент'},
        // {value: '', text: 'Действие'},
      ],
      clients: [],
      searchPhones: null,
      client_id: null,
      phones: [],
      datasets: [],
      barChartData: {},
      stats: null,
      query: null,
    }

  },
  methods: {
    setPage(page) {
      this.page = page;
      this.getClients();
    },
    prepareParams() {
      let params = JSON.parse(JSON.stringify(this.filters));
      params.page = this.page;
      params.chart = this.showChart ? 1 : 0;
      params.revenueTable = this.showRevenueCost ? 1 : 0;
      params.revenueTableByOrders = this.revenueTableByOrders;
      if (+params.client_f.ordersCount) params.client_f.ordersCount = params.client_f.ordersCountSign + (+params.client_f.ordersCount);
      let moreOrEq = ['bonus'];
      moreOrEq.forEach((field) => {
        if (+params.client_f[field]) params.client_f[field] = '>=' + (+params.client_f[field]);
        else params.client_f[field] = '';
      })
      return params;
    },
    filtersUpdated() {
      if (!this.showChart) return;
      if (this.filters.order.dateclean[0] && this.filters.order.dateclean[1] || !this.filters.order.dateclean[0] && !this.filters.order.dateclean[1]) {
        this.getClients();
      }
    },
    renderbarChartData() {
      let sameDatasetLength = this.datasets.length === Object.keys(this.stats.datasets).length;
      this.datasets = [];
      if (!this.stats) return {};
      let i = 0;
      for (let label in this.stats.datasets) {
        let info = label.split('|');
        let hidden = !info[0].includes('Исполнено');
        if (sameDatasetLength) {
          hidden = !this.$refs.chart.getCurrentChart().isDatasetVisible(i);
        }
        this.datasets.push({
          label: info[0],
          backgroundColor: info[1],
          borderColor: info[1],
          data: this.stats.datasets[label],
          datalabels: {
            align: 'end',
            anchor: 'start'
          },
          tension: 0.2,
          hidden: hidden,
        })
        i++;
      }
      return {
        labels: this.stats.labels.map(date => {
          return `${this.$moment(date).format('ddd, DD.MM')}`
        }),
        datasets: this.datasets,
      };
    },
    getClients() {
      // return;
      try {
        this.$router.push({query: {}});
        let f = {...this.filters};
        this.$router.push({query: f})
      } catch (e) {
        console.log('router query exception')
      }
      this.loading_table = true;
      this.clients = [];
      this.stats = null;

      this.$store.state.server.request('client/get', this.prepareParams(), (data) => {
        this.clients = data.response;
        if (parseInt(data.count)) this.count = parseInt(data.count);
        if (data.revenueTable) this.revenueTable = data.revenueTable;
        if (data.stats) {
          this.stats = data.stats;
          this.barChartData = this.renderbarChartData();
        }
        // if (this.clients.length === 1 && !this.clients[0].hasOwnProperty('name')) {
        //   this.$eventBus.$emit('openClient', this.clients[0].n_id);
        // }
        this.loading_table = false;
      }, () => {
        this.loading_table = false
      })
    },
    openNewClientModal() {
      this.$refs.newClientModal.open();
    }
  },
  computed: {
    downloadURL() {
      let params = this.prepareParams();
      params.csv = 1;
      return this.$store.state.server.genUrl('client/get', params);
    },
  },
  mounted() {
    for (let i in this.$route.query) if (['order', 'client_f', 'address'].includes(i)) this.filters[i] = {...this.$route.query[i]};
    this.getClients()
  },
}
</script>
<style scoped lang="scss">

</style>
